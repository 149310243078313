<template>
	<div class="login">
		<div
			style="text-align: center; position: absolute; top: 24%; right: 18%"
		>
			<img src="@/assets/lg.png" alt="" />

			<div
				style="
					padding: 8px;
					background: rgba(255, 255, 255, 0.3);
					border-radius: 6px;
				"
			>
				<div
					class="login-container"
					style="
						background: rgba(255, 255, 255, 1);
						border-radius: 6px;
					"
				>
					<el-tabs
						stretch
						v-model="loginState"
						@tab-click="
							() => {
								resetForm('ruleForm');
							}
						"
					>
						<el-tab-pane label="密码登录" name="true"></el-tab-pane>
						<el-tab-pane
							label="验证码登录"
							name="false"
						></el-tab-pane>
					</el-tabs>
					<el-form
						:model="ruleForm"
						status-icon
						:rules="rules"
						ref="ruleForm"
						class="demo-ruleForm"
						style="width: 400px"
						label-position="left"
						label-width="0px"
					>
						<el-form-item prop="userName">
							<el-input
								prefix-icon="el-icon-mobile-phone"
								placeholder="请输入手机号"
								v-model="ruleForm.userName"
							></el-input>
						</el-form-item>
						<el-form-item
							style="margin-bottom: 10px"
							v-if="JSON.parse(loginState)"
							prop="password"
						>
							<el-input
								prefix-icon="el-icon-lock"
								type="password"
								v-model="ruleForm.password"
								autocomplete="off"
								placeholder="请输入密码"
								@keyup.enter.native="submitForm('ruleForm')"
							></el-input>
						</el-form-item>
						<el-form-item
							style="margin-bottom: 10px"
							v-else
							class="usercode"
							prop="usercode"
						>
							<el-input
								autocomplete="off"
								prefix-icon="el-icon-message"
								placeholder="请输入验证码"
								onkeyup="value=value.replace(/[^\d]/g,'')"
								v-model="ruleForm.usercode"
								@keyup.enter.native="submitForm('ruleForm')"
							></el-input>
							<el-button
								style="margin-left: 10px; width: 110px"
								:disabled="!isNaN(codeText)"
								@click="getCode"
								>{{ codeText }}</el-button
							>
						</el-form-item>
						<el-form-item style="margin-bottom: 10px">
							<div class="register">
								<el-checkbox
									v-if="JSON.parse(loginState)"
									v-model="checked"
									>记住密码</el-checkbox
								>
								<div v-else></div>
								<div @click="$router.replace('/register')">
									注册账户
								</div>
							</div>
						</el-form-item>
						<el-form-item style="margin-bottom: 0px">
							<el-button
								style="width: 100%; background: #21b533"
								type="primary"
								@click="submitForm('ruleForm')"
								>登录</el-button
							>
						</el-form-item>
						<el-form-item style="margin-bottom: 0px">
							<div
								style="
									text-align: end;
									color: #1890ff;
									cursor: pointer;
								"
								@click="$router.replace('/forgetpassword')"
							>
								忘记密码
							</div>
						</el-form-item>
					</el-form>

					<div class="user-login-other1">
						点击登录即代表您确认同意了悦融易
						<router-link class="myregister" :to="{ name: 'policy' }"
							><span style="color: #050505; cursor: pointer"
								>《用户协议及隐私条款》</span
							>
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	checklogin,
	sendSMSCode,
	checkSMSCode,
	roleList,
} from '@/api/index.js';
export default {
	name: 'Login',
	data() {
		const checkUserName = (rule, value, callback) => {
			let reg = /^1[345789]\d{9}$/;
			if (!reg.test(value)) {
				callback(new Error('请输入正确的手机号'));
			} else {
				callback();
			}
		};
		const validatePass = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入密码'));
			} else {
				if (this.ruleForm.checkPass !== '') {
					this.$refs.ruleForm.validateField('checkPass');
				}
				callback();
			}
		};
		const usercode = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入验证码'));
			} else {
				if (this.ruleForm.checkPass !== '') {
					this.$refs.ruleForm.validateField('checkPass');
				}
				callback();
			}
		};
		return {
			ruleForm: {
				password: '',
				userName: '',
				usercode: '',
				systematic: '2',
			},
			rules: {
				password: [{ validator: validatePass, trigger: 'blur' }],
				userName: [{ validator: checkUserName, trigger: 'blur' }],
				usercode: [{ validator: usercode, trigger: 'blur' }],
			},
			loginState: 'true',
			codeText: '获取验证码',
			Timeout: null,
			checked: false,
		};
	},
	created() {
		if (JSON.parse(localStorage.getItem('record'))) {
			const { password, userName } = JSON.parse(
				localStorage.getItem('record')
			);
			this.ruleForm.password = password;
			this.ruleForm.userName = userName;
			this.checked = true;
		}
	},
	methods: {
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					if (JSON.parse(this.loginState)) {
						this.login();
						// const RouteView = {
						// 	name: 'RouteView',
						// 	render: (h) => h('router-view'),
						// };
						// let routerinfo = [
						// 	{
						// 		path: '/',
						// 		name: 'home',
						// 		component: 'views/home/home',
						// 		redirect: '/index',
						// 		children: [
						// 			{
						// 				path: '/index',
						// 				name: 'index',
						// 				component: RouteView,
						// 				redirect: '/index/info',
						// 				meta: { name: '首页' },
						// 				children: [
						// 					{
						// 						path: '/index/info',
						// 						name: 'indexInfo',
						// 						component: 'views/index/index',
						// 						meta: { name: '首页详情' },
						// 					},
						// 				],
						// 			},
						// 			{
						// 				path: '/dealList',
						// 				name: 'dealList',
						// 				component: RouteView,
						// 				redirect: '/dealList/info',
						// 				meta: {
						// 					name: '列表',
						// 				},
						// 				children: [
						// 					{
						// 						path: '/dealList/info',
						// 						name: 'dealListInfo',
						// 						component:
						// 							'views/dealList/dealList',
						// 						meta: { name: '列表详情' },
						// 					},
						// 				],
						// 			},
						// 			{
						// 				path: '/order',
						// 				name: 'order',
						// 				component: RouteView,
						// 				redirect: '/order/info',
						// 				meta: {
						// 					name: '订单',
						// 				},
						// 				children: [
						// 					{
						// 						path: '/order/info',
						// 						name: 'orderInfo',
						// 						component: 'views/order/order',
						// 						meta: { name: '订单详情' },
						// 					},
						// 				],
						// 			},
						// 			{
						// 				path: '/userinfo',
						// 				name: 'userinfo',
						// 				component: RouteView,
						// 				redirect: '/userinfo/info',
						// 				meta: {
						// 					name: '个人中心',
						// 				},
						// 				children: [
						// 					{
						// 						path: '/userinfo/info',
						// 						name: 'userinfoInfo',
						// 						component:
						// 							'views/userinfo/userinfo',
						// 						meta: { name: '个人中心详情' },
						// 					},
						// 					{
						// 						path:
						// 							'/userinfo/changepassword',
						// 						name: 'changepassword',
						// 						component:
						// 							'views/changepassword/changepassword',
						// 						meta: { name: '修改密码' },
						// 					},
						// 				],
						// 			},
						// 			{
						// 				path: '/orderlist',
						// 				name: 'orderlist',
						// 				component: RouteView,
						// 				redirect: '/orderlist/info',
						// 				meta: {
						// 					name: '订单列表',
						// 				},
						// 				children: [
						// 					{
						// 						path: '/orderlist/info',
						// 						name: 'orderlistInfo',
						// 						component:
						// 							'views/orderlist/orderlist',
						// 						meta: { name: '订单列表详情' },
						// 					},
						// 				],
						// 			},
						// 		],
						// 	},
						// ];
						// localStorage.setItem('token', 'res.token');
						// this.$store.dispatch('token', 'res.token');
						// this.$store.commit('asyncRouter', routerinfo);
						// localStorage.setItem(
						// 	'asyncRouter',
						// 	JSON.stringify(routerinfo)
						// );
						// this.$router.replace('/index');
					} else {
						this.checkSMSCode();
					}
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		resetForm(formName) {
			this.ruleForm.usercode = '';
			this.$refs[formName].resetFields();
		},
		async getCode() {
			const res = await sendSMSCode({ mobile: this.ruleForm.userName });

			if (res.returncode !== 0) {
				// this.$message({
				// 	message: res.message,
				// 	type: 'warning',
				// });
				return false;
			}
			this.$message({
				message: '验证码已发送！',
				type: 'success',
			});
			clearInterval(this.Timeout);
			this.codeText = 60;
			this.Timeout = setInterval(() => {
				if (this.codeText == 1) {
					clearInterval(this.Timeout);
					this.codeText = '获取验证码';
					return false;
				}
				this.codeText = this.codeText - 1;
			}, 1000);
		},
		async login() {
			const res = await checklogin(this.ruleForm);
			if (res.returncode === 0) {
				localStorage.setItem('userInfo', JSON.stringify(res));
				this.$store.commit('setUserInfo', res);

				this.roleList({ userId: res.userId });
			}
		},
		async checkSMSCode() {
			const res = await checkSMSCode({
				mobile: this.ruleForm.userName,
				systematic: '2',
				SMSVerificationCode: this.ruleForm.usercode,
			});
			if (res.returncode === 0) {
				localStorage.setItem('userInfo', JSON.stringify(res));
				this.$store.commit('setUserInfo', res);
				this.roleList({ userId: res.userId });
			} else {
				this.$message({
					message: res.message,
					type: 'warning',
				});
			}
		},
		async roleList(data) {
			const res = await roleList(data);
			// if (res.menuList.length > 0) {
			const RouteView = {
				name: 'RouteView',
				render: (h) => h('router-view'),
			};
			let routerinfo = [
				{
					path: '/',
					name: 'home',
					component: 'views/home/home',
					redirect: '/index',
					children: [
						{
							path: '/index',
							name: 'index',
							component: RouteView,
							redirect: '/index/info',
							meta: { name: '首页' },
							children: [
								{
									path: '/index/info',
									name: 'indexInfo',
									component: 'views/index/index',
									meta: {
										name: '首页',
										icon: 'icon-shouye',
										isShow: true,
									},
								},
								{
									path: '/index/mycustomer',
									name: 'myCustomer',
									component: 'views/mycustomer/mycustomer',
									meta: {
										name: '悦融易推荐给我的客户',
										icon: 'icon-kehuguanli',
										isShow: true,
									},
								},
								{
									path: '/index/companycustomer',
									name: 'companyCustomer',
									component:
										'views/companycustomer/companycustomer',
									meta: {
										name: '我推荐给悦融易的客户',
										icon: 'icon-kehu',
										isShow: true,
									},
								},
								{
									path: '/index/inquiry',
									name: 'inquiry',
									component: 'views/inquiry/inquiry',
									meta: {
										name: '悦融易客户询单',
										icon: 'icon-xundan',
										isShow: true,
									},
								},
								{
									path: '/index/scheme',
									name: 'scheme',
									component: 'views/scheme/scheme',
									meta: {
										name: '客户的方案',
										icon: 'icon--fanganguanli',
										isShow: true,
									},
								},
								{
									path: '/index/approval',
									name: 'approval',
									component: 'views/approval/approval',
									meta: {
										name: '客户的批复',
										icon: 'icon-pifu',
										isShow: true,
									},
								},
								{
									path: '/index/dataanalysis',
									name: 'dataanalysis',
									component:
										'views/dataanalysis/dataanalysis',
									meta: {
										name: '银行流水分析',
										icon: 'icon-yinhangliushui',
										isShow: true,
									},
								},
								{
									path: '/index/financial',
									name: 'financial',
									component: 'views/financial/financial',
									meta: {
										name: '财务数据分析',
										icon: 'icon-ziyuanx',
										isShow: true,
									},
								},
								{
									path: '/index/myproduct',
									name: 'myproduct',
									component: 'views/myproduct/myproduct',
									meta: {
										name: '我机构产品',
										icon: 'icon-productManagement',
										isShow: true,
									},
								},

								{
									path: '/index/branch',
									name: 'branch',
									component: 'views/branch/branch',
									meta: {
										name: '我的网点',
										icon: 'icon-wangdian',
										isShow: true,
									},
								},
								{
									path: '/index/myinfo',
									name: 'userinfoInfo',
									component: 'views/userinfo/userinfo',
									meta: {
										name: '我的信息',
										icon: 'icon-wodexinxi_jibenxinxi',
										isShow: true,
									},
								},
								{
									path: '/index/notice',
									name: 'notice',
									component: 'views/notice/notice',
									meta: {
										name: '合作须知',
										icon: 'icon-lvshigaozhihan',
										isShow: true,
									},
								},
								{
									path: '/index/changepassword',
									name: 'changepassword',
									component:
										'views/changepassword/changepassword',
									meta: {
										name: '修改密码',
										icon: 'icon-iconfont11',
										isShow: false,
									},
								},
								{
									path: '/index/notification',
									name: 'notification',
									component:
										'views/notification/notification',
									meta: {
										name: '通知管理',
										isShow: false,
									},
								},
							],
						},
						// {
						// 	path: '/userinfo',
						// 	name: 'userinfo',
						// 	component: RouteView,
						// 	redirect: '/userinfo/info',
						// 	meta: {
						// 		name: '个人中心',
						// 	},
						// 	children: [

						// 	],
						// },
						// {
						// 	path: '/orderlist',
						// 	name: 'orderlist',
						// 	component: RouteView,
						// 	redirect: '/orderlist/info',
						// 	meta: {
						// 		name: '订单列表',
						// 	},
						// 	children: [
						// 		{
						// 			path: '/orderlist/info',
						// 			name: 'orderlistInfo',
						// 			component: 'views/orderlist/orderlist',
						// 			meta: { name: '订单列表详情' },
						// 		},
						// 	],
						// },
					],
				},
			];
			this.$store.commit('asyncRouter', routerinfo);
			localStorage.setItem('asyncRouter', JSON.stringify(routerinfo));
			if (JSON.parse(this.loginState) && this.checked) {
				localStorage.setItem(
					'record',
					JSON.stringify({
						password: this.ruleForm.password,
						userName: this.ruleForm.userName,
					})
				);
			}

			if (!this.checked) {
				localStorage.removeItem('record');
			}
			this.$router.replace('/index');
			// }
		},
	},
};
</script>

<style lang="scss" scoped>
.user-login-other1 {
	font-size: 12px;
	color: #999;
	position: absolute;
	bottom: 40px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.login {
	overflow: auto;
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
	background: url(~@/assets/bj.png) no-repeat;
	background-size: 100% 100%;
	padding-top: 70px;
	img {
		height: 44px;
		margin-bottom: 40px;
	}
}
/* reset element-ui css */
.login-container {
	display: flex;
	justify-content: center;
	padding: 70px 20px 50px 20px;
	position: relative;
	width: 345px;
}
.login-text {
	position: absolute;
	z-index: 1;
	left: 20px;
	top: 20px;
	color: #1890ff;
	cursor: pointer;
}
.register {
	color: #1890ff;
	cursor: pointer;
	width: 100%;
	display: flex;
	bottom: 30px;
	justify-content: space-between;
}
::v-deep .usercode > div {
	display: flex;
}
::v-deep .el-tabs__nav-wrap::after {
	background-color: transparent;
}
::v-deep .el-tabs {
	position: absolute;
	top: 10px;
	width: 65%;
}
</style>
